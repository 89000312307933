export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 384,
            //             phoneElementId: 392,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof wheeled inspection robot for chemical industry',
            //                     sort: 1
            //                 }],
            //             type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 385,
            //             phoneElementId: 393,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof wheeled inspection robot for chemical industry',
            //                     sort: 1
            //                 }, {
            //                     descName: 'It can realize all-weather and full coverage inspection of the equipment area, and has multiple functions such as meter identification, leakage, tank liquid level, gas monitoring, infrared temperature measurement, and abnormal monitoring. It can effectively solve the common pain points and difficulties in daily inspections in the petrochemical industry,help enterprises improve the level of safe operation and escort the safety of factory production.',
            //                     sort: 2
            //                 },],
            //             type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 386,
            //             phoneElementId: 394,
            //             descList: [
            //                 {
            //                     descName: 'Inspection data management',
            //                     sort: 1
            //                 }, {
            //                     descName: `The SevnceRobotics inspection platform can be used to view, set and manage the robot's operating status, inspection information, inspection item results, inspection map, environmental information, ledger data, inspection video, early warning information and other contents, fully realize information dataization, and improve the digital operation and maintenance capabilities of enterprises.`,
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 387,
            //             phoneElementId: 395,
            //             descList: [
            //                 {
            //                     descName: 'Meter identification readings',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Read various instrument data through AI visual technology, and generate warnings when the collected data exceeds the threshold.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 388,
            //             phoneElementId: 396,
            //             descList: [
            //                 {
            //                     descName: 'Leakage monitoring',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Use AI visual identification technology and other means to detect leakage, which can display the coordinates of the leakage location and issue warnings.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 389,
            //             phoneElementId: 397,
            //             descList: [
            //                 {
            //                     descName: 'Equipment infrared temperature measurement',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with an infrared thermal imager, it can collect the surface temperature of the equipment and issue warnings for abnormal equipment temperature.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 390,
            //             phoneElementId: 398,
            //             descList: [
            //                 {
            //                     descName: 'Environmental gas monitoring',
            //                     sort: 1
            //                 }, {
            //                     descName: 'It can monitor flammable, explosive, toxic and harmful gases such as oxygen, carbon monoxide, combustible gas, and hydrogen sulfide in real time.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: 400,
            //             descList: [
            //                 {
            //                     descName: 'Model',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Dimension',
            //                     sort: 2
            //                 }, {
            //                     descName: 'Curb weight',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Explosion-proof grade',
            //                     sort: 4
            //                 }, {
            //                     descName: 'Protection Level',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Walking speed',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Drive mode',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Grade ability',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Obstacle Surmounting ability',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: 'Wading depth',
            //                     sort: 10
            //                 }, {
            //                     descName: 'Battery Endurance',
            //                     sort: 11
            //                 }, {
            //                     descName: 'Configuration',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: '',
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof wheeled inspection robot for chemical industry entry-Level model',
            //                     sort: 1
            //                 }, {
            //                     descName: '≤1420mmX820mmX1040(length×width×height)',
            //                     sort: 2
            //                 }, {
            //                     descName: '≤350kg',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 4
            //                 }, {
            //                     descName: 'IP65',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '0-0.8m/s,speed adjustable',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Four-wheel two-wheel drive',
            //                     sort: 7
            //                 }, {
            //                     descName: '<15°',
            //                     sort: 8
            //                 }, {
            //                     descName: '100mm',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 10
            //                 }, {
            //                     descName: '≥3h',
            //                     sort: 11
            //                 }, {
            //                     descName: 'Robot body, remote control, 2 standard algorithms, wired charger',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: '',
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof wheeled inspection robot for chemical industry basic model',
            //                     sort: 1
            //                 }, {
            //                     descName: '≤1420mmX820mmX1055(length×width×height)',
            //                     sort: 2
            //                 }, {
            //                     descName: '≤350kg',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 4
            //                 }, {
            //                     descName: 'IP65',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '0-0.8m/s,speed adjustable',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Four-wheel two-wheel drive',
            //                     sort: 7
            //                 }, {
            //                     descName: '20°',
            //                     sort: 8
            //                 }, {
            //                     descName: '100mm',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 10
            //                 }, {
            //                     descName: '≥4h',
            //                     sort: 11
            //                 }, {
            //                     descName: 'Robot body, remote control, 4 standard algorithms, explosion-proof charging pile',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: '',
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof wheeled inspection robot for chemical industry value model',
            //                     sort: 1
            //                 }, {
            //                     descName: '≤1420mmX820mmX1055(length×width×height)',
            //                     sort: 2
            //                 }, {
            //                     descName: '≤400kg',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 4
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '0-1m/s,speed adjustable',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Four-wheel four-wheel drive',
            //                     sort: 7
            //                 }, {
            //                     descName: '20°',
            //                     sort: 8
            //                 }, {
            //                     descName: '120mm',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 10
            //                 }, {
            //                     descName: '≥5h',
            //                     sort: 11
            //                 }, {
            //                     descName: 'Robot body, remote control, 5 standard algorithms, explosion-proof charging pile',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 11
            //         },
            //         {
            //             pcElementId: 391,
            //             phoneElementId: 399,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof wheeled inspection robot for chemical industry',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Replacing people with machines, enjoying a new safety experience',
            //                     sort: 2
            //                 },],
            //             type: 'image',
            //             sort: 12
            //         }
            //     ],
            elmentList: [],
        }
    },
    created() {
        //    this.$store.dispatch("pageset/getSourceData",
        //             {
        //                 createBy: '',
        //                 updateBy: '',
        //                 elmentList: this.elmentList,
        //                 pageId: this.$route.query.pageId,//页面id
        //                 pageName: this.$route.path,
        //                 path: this.$route.path,
        //                 remark: '',
        //             }
        //         );

        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
            this.$router.push('/pdfView')
        },

    }
}